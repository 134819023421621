import axios from "axios";
import { APIEndpoint} from "../UrlPaths";

const token = localStorage.getItem("win_access_token");

export async function getAllMatches() {
  try {
    const response = await axios.get(APIEndpoint + "/getAllMatches", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function matchActions(data) {
  try {
    const response = await axios.post(APIEndpoint + "/storeMatchDetails", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getMatchDetails(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/getMatchDetails`, data,
      { headers: { 'Authorization': `Bearer ${token}`} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}


export async function storeMatchResults(data) {
  try {
    const response = await axios.post(APIEndpoint + "/storeMatchResults", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getCountries() {
  try {
    const response = await axios.get(
        `${APIEndpoint}/getActiveCountries`,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response.data;
  } catch (error) {

    return error;
  }
}


export async function getIPLTeams() {
  try {
    const response = await axios.get(APIEndpoint + "/getIPLTeams", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getMatchFormat() {
  try {
    const response = await axios.get(APIEndpoint + "/getMatchFormat", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getThirdPartyMatchList(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getThirdPartyMatchList", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function storeThirdPartyMatchScore(data) {
  try {
    const response = await axios.post(APIEndpoint + "/storeMatchScoreData", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getFormatBaseTeams(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getActiveTeams",data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}