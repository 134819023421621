import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {Table, Input, Button, DatePicker, Modal, TimePicker, Space, Radio,Select} from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import {
  getAllMatches,
  getCountries, getFormatBaseTeams, getIPLTeams,
  getMatchDetails, getMatchFormat,
  getThirdPartyMatchList,
  matchActions,
  storeMatchResults,
  storeThirdPartyMatchScore
} from "../services/MatchAPIServices";
import {GET_MATCH_FORMAT, GET_MATCH_STATUS} from "../AppConstant";
import moment from "moment/moment";
import {displayUrl} from "../UrlPaths";
import {getSeriesList} from "../services/MatchSeriesAPIServices";
import $ from 'jquery';
const { Option } = Select;

function Matches({ t }) {
  const navigate = useNavigate();
  const spinner = document.getElementById("spinner");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [matchFormatList, setMatchFormatList] = useState([]);
  const [modalTitle, setModalTitle] = useState("Add New Match");
  const [visible, setVisible] = useState(false);
  const [match_date, setMatchDate] = useState("");
  const [match_no, setMatchNo] = useState("");
  const [format, setFormat] = useState("");
  const [series, setSeries] = useState("");
  const [venue, setVenue] = useState("");
  const [match_time, setMatchTime] = useState("");
  const [team_A, setTeamA] = useState("");
  const [team_B, setTeamB] = useState("");
  const [status, setStatus] = useState("UPCOMING");
  const [match_id, setMatchId] = useState("0");
  const [is_active, setIsActive] = useState("1");
  const [matchEdit, setMatchEdit] = useState(false);
  const [display_order_1, setDisplayOrder1] = useState(team_A);
  const [toss, setToss] = useState("");
  const [day_night, setDayNight] = useState("");

  const [resultModalTitle, setResultModalTitle] = useState("Match Results");
  const [resultModalVisible, setResultModalVisible] = useState(false);
  const [team_A_overs, setTeamAOvers] = useState("");
  const [team_B_overs, setTeamBOvers] = useState("");
  const [team_A_score, setTeamAScore] = useState("");
  const [team_B_score, setTeamBScore] = useState("");
  const [match_results, setMatchResults] = useState("");
  const [selected_team_A, setSelectedTeamA] = useState("");
  const [selected_team_B, setSelectedTeamB] = useState("");
  const [winning_team, setWinningTeam] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [selected_team_A_flag, setSelectedTeamAFlag] = useState("");
  const [selected_team_B_flag, setSelectedTeamBFlag] = useState("");
  const timeFormat = 'HH:mm';
  const [seriesList, setSeriesList] = useState([]);
  const [thirdPartyMatchList, setThirdPartyMatchList] = useState([]);
  const [cricbuzz_match_id, setCricbuzzMatchId] = useState("");
  const [isThirdPartyUpdating, setIsThirdPartyUpdating] = useState(false);

  let event_list = [];

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sortDirections: ["descend"],
      hidden: true,
    },
    {
      key: "series",
      title: "Series",
      dataIndex: "series",
      filterMode: "tree",
      filterSearch: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type series"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.series.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "info",
      title: "Teams",
      align: 'center',
      render: (record) => {
        return (
            <>
              <div className="flex">
                <p className="text-bold-500">
                  <img src={displayUrl+'/'+record.team_A_flag_path} width={35} />
                  <br/> VS <br/> <img src={displayUrl+'/'+record.team_B_flag_path} width={35} /> </p>
              </div>
            </>
        );
      }
    },
    {
      key: "info",
      title: "",
      render: (record) => {
        return (
            <>
              <div className="flex">
                <p><span className="text-bold-500">{record.format} - {record.match_no}</span> <br/>{record.venue}</p>
                <span className="text-muted">{record.match_date} - {record.match_time}</span>
              </div>
            </>
        );
      }
    },
    {
      key: "status",
      title: "Match Status",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
              {record.status === "LIVE" ? (
                <span className="badge bg-light-danger">{record.status}</span>
              ) : (
                  record.status === "UPCOMING" ? (
                      <span className="badge bg-light-info">{record.status}</span>
                  ):(
                      <span className="badge bg-light-warning">{record.status}</span>
                  )
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "match_results",
      title: "Results",
      render: (record) => {
        return (
            <>
              {" "}
              <div className="flex">
                {record.status === "UPCOMING" ? (<>
                      <button className="badge badge-pill bg-light-info" style={{border:0}}   onClick={(e) => handleMatchResultForm(record.id)}>
                        <i className="ft-plus-circle"></i>
                      </button>
                    </>
                ) : (
                    <p>{record.match_results} &nbsp;
                      <button className="badge badge-pill bg-light-info" style={{border:0}}   onClick={(e) => handleMatchResultForm(record.id)}>
                        <i className="ft-edit"></i>
                      </button>
                    </p>
                )}
              </div>
            </>
        );
      },
    },
    {
      key: "",
      title: "",
      render: (record) => {
        return (
            <>
              {" "}
            
              <div className="flex">
                <button className="btn btn-sm bg-light-warning" id={`updateBtn`+record.id} onClick={(e) => updatethirdPartyScoreData(record.id,record.cricbuzz_match_id)}>
                Update Score
                </button>
            </div>
         
            </>
        );
      },
    },
    {
      key: "is_active",
      title: "Status",
      render: (record) => {
        return (
            <>
              {" "}
              <div className="flex">
                {record.is_active == "1" ? (
                    <span className="badge badge-pill badge-success">Active</span>
                ) : (
                    <span className="badge badge-pill badge-danger">Inactive</span>
                )}
              </div>
            </>
        );
      },
    },
    {
      key: "action",
      title: "",
      render: (record) => {
        return (
          <>
            <div className="flex">
                <button className="btn btn-sm bg-light-danger" onClick={(e) => handleEditMatchForm(record.id)}>
                  <i class="ft-edit"></i>
                </button>
            </div>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    dataLoad();
    setStatusList(GET_MATCH_STATUS);

    getSeriesList().then((response) => {
      setSeriesList(response);
    });

    getMatchFormat().then((response) => {
      setMatchFormatList(response);
    });

  }, []);

  const dataLoad = () =>{
    setLoading(true);
    getAllMatches().then((response) => {
      event_list = response;
      setTableData(response);
      setLoading(false);
    });
    const selected_serise = {serise_id:series}
    getThirdPartyMatchList(selected_serise).then((response) => {
      response.status_code == 1 ? setThirdPartyMatchList(response.data): setThirdPartyMatchList([])
    });
  }

  useEffect(() => { 
    const selected_serise = {serise_id:series}
    getThirdPartyMatchList(selected_serise).then((response) => {
      response.status_code == 1 ? setThirdPartyMatchList(response.data): setThirdPartyMatchList([])
    });
  }, [series]);
  const handleSubmit = (e) => {
    if(cricbuzz_match_id == "" || cricbuzz_match_id == 0 || cricbuzz_match_id == null){
      Swal.fire("Warning", "Need to select a source for scores.", "warning");
    }else{
      const data ={ match_no,format,series,status,venue,match_date,match_time,team_A,team_B,match_id,is_active,display_order_1,toss,day_night,cricbuzz_match_id}
      matchActions(data).then((response) => {
        if (response.status_code == 1) {
          Swal.fire("Success", " " + response.message, "success");
          setVisible(false);
          dataLoad();
        } else {
            Swal.fire("Warning", " " + response.message, "warning");
        }
  });

    }
   
  }
  const handleNewMatchForm = () => {
    setSeries("");
    setMatchNo("");
    setVenue("");
    setTeamA("");
    setTeamB("");
    setFormat("");
    setMatchDate("");
    setCricbuzzMatchId("");
    setMatchDate(null)
    setMatchTime("");
    setIsActive("1");
    setMatchId("0");
    setMatchEdit(false);
    setStatus("UPCOMING");
    setModalTitle("Add New Match");
    setVisible(true);
  }

  const onChangeMatchTime  = (time,timeString) => {
    setMatchTime(timeString)
  };

  const onChangeTeamA = (selected_country) => {
    setTeamA(selected_country);
  }

  const onChangeTeamB = (selected_country) => {
    setTeamB(selected_country);
  }

  const onChangeDate = (date, dateString) => {
    setMatchDate(dateString);

  };

  const handleMatchResultForm = (match_id) => {

    const data_json  = {match_id:match_id};

    getMatchDetails(data_json).then((response) => {
      if (response.status_code == 1) {
        setMatchId(match_id);
        setSelectedTeamA(response.data.team_A == null ? "" : response.data.team_A);
        setSelectedTeamAFlag(response.data.team_A_flag_path);
        setSelectedTeamB(response.data.team_B == null ? "" : response.data.team_B);
        setSelectedTeamBFlag(response.data.team_B_flag_path);
        setTeamAOvers(response.data.team_A_overs == null ? "" : response.data.team_A_overs);
        setTeamBOvers(response.data.team_B_overs == null ? "" : response.data.team_B_overs);
        setTeamAScore(response.data.team_A_score == null ? "" : response.data.team_A_score);
        setTeamBScore(response.data.team_B_score == null ? "" : response.data.team_B_score);
        setMatchResults(response.data.match_results == null ? "" : response.data.match_results);
        setWinningTeam(response.data.winning_team == null ? "" : response.data.winning_team)
        setCricbuzzMatchId(response.data.cricbuzz_match_id == null ? "" : response.data.cricbuzz_match_id);

        if(response.data.status == 'UPCOMING'){
          setStatus("LIVE");
        }else{
          setStatus(response.data.status);
        }

        setResultModalTitle("Match Results - "+ response.data.series+ ' - '+response.data.format);
        setResultModalVisible(true);
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
      }
    });
  }

  const handleResultsSubmit = (e) => {

    const data ={ match_id, team_A_overs, team_B_overs, team_A_score, team_B_score, status, match_results, winning_team, cricbuzz_match_id}
    if(cricbuzz_match_id == "" || cricbuzz_match_id == 0 || cricbuzz_match_id == null){
      Swal.fire("Warning", "Need to select a source for scores using editing option.", "warning");
    }else{
      storeMatchResults(data).then((response) => {
        if (response.status_code == 1) {
          Swal.fire("Success", " " + response.message, "success");
          setResultModalVisible(false);
          dataLoad();
        } else {
          Swal.fire("Warning", " " + response.message, "warning");
        }
      });
    }
   

  }

  const handleEditMatchForm = (match_id) => {
    const data_json  = {match_id:match_id};

    getMatchDetails(data_json).then((response) => {
      if (response.status_code == 1) {
        setSeries(response.data.series_id);
        setMatchNo(response.data.match_no);
        setVenue(response.data.venue);
        setTeamA(response.data.team_A);
        setTeamB(response.data.team_B);
        setFormat(response.data.format);
        setMatchDate(response.data.match_date);
        setMatchTime(response.data.match_time);
        setStatus(response.data.status);
        setIsActive(response.data.is_active);
        setDisplayOrder1(response.data.display_order_1);
        setWinningTeam(response.data.winning_team);
        setToss(response.data.toss);
        setDayNight(response.data.day_night);
        setMatchId(match_id);
        setCricbuzzMatchId(response.data.cricbuzz_match_id == null ? "" : response.data.cricbuzz_match_id);

        if(parseInt(response.data.format) <= 4){
          var format_text = 'COUNTRY';
        }else{
          var format_text = $("#match-format-selection option:selected").text();
        }
        const selection_json = {type:format_text};
        getFormatBaseTeams(selection_json).then((response) => {
          setCountryList(response);
        });
        setMatchEdit(true)
        setModalTitle("Edit Match - "+response.data.series);
        setVisible(true);
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
      }

    });

  }


  const onChangeOrder = (e) => {
    setDisplayOrder1(e.target.value);
  };

  const onChangeWinningTeam = (e) => {
    setWinningTeam(e.target.value);
  };

  const formatOnChange = (e)=>{

    let formatValue = e.target.value;
    setFormat(formatValue);
    if(parseInt(formatValue) <= 4){
      var format_text = 'COUNTRY';
    }else{
      var format_text = $("#match-format-selection option:selected").text();
    }
    const selection_json = {type:format_text};
    getFormatBaseTeams(selection_json).then((response) => {
      setCountryList(response);
    });
    
  }

  const onChangeDayNight = (e) => {
    setDayNight(e.target.value);
  };

  const onChangeThirdPartyMatch = (value) => {
    setCricbuzzMatchId(value);
  };
  const onSearchSeriesThirdPartyMatch = (value) => {

  };

  // Filter `option.label` match the user type `input`
  const filterOptionSeriesMatch = (input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const updatethirdPartyScoreData = (match_id,cricbuzzMatchId) => {
    if(cricbuzzMatchId == "" || cricbuzzMatchId == 0 || cricbuzzMatchId == null){
      Swal.fire("Warning", "Need to select a source for scores using editing option.", "warning");
    }else{
      $('#updateBtn'+match_id).text('processing...')
      $("#updateBtn"+match_id).prop("disabled",true);
      const ids_json  = {match_id,cricbuzzMatchId};
  
      storeThirdPartyMatchScore(ids_json).then((response) => {
        if (response.status_code == 1) {
          Swal.fire("Success", " " + response.message, "success");
          
          dataLoad();
        } else {
          Swal.fire("Warning", " " + response.message, "warning");
        }
        $('#updateBtn'+match_id).text('Update Score')
        $("#updateBtn"+match_id).prop("disabled",false);
      });
    }
   
  }

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Matches</h3>
          <p style={{ color: "grey" }}>
            Create, edit, and manage the match scores.
          </p>
        </div>
        <div className="col-4">
          <button
              type="button"
              className="btn btn-primary mb-3 d-block  float-right"
              onClick={(e) => handleNewMatchForm(e)}
          >
            <i className="ft-plus"></i> New Match
          </button>

        </div>
      </div>

      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
          title={modalTitle}
          maskClosable={false}
          visible={visible}
          onCancel={() => setVisible(false) }
          onOk={(e) =>  {
            handleSubmit(e);
          }}
          footer={[
            <Button key="back" onClick={() => setVisible(false)}> Cancel </Button>,
            <Button key="submit" type="primary" onClick={handleSubmit}> Save </Button>,
          ]}
      >
          <div className="row">
            <div className="col-6">
              <label className="form-label">
                Match No <span style={{ color: "red" }}>*</span>
              </label>
              <input
                  type="text"
                  className="form-control"
                  value={match_no}
                  min={1}
                  onChange={(e) => setMatchNo(e.target.value)}
                  placeholder="Enter match no"
              />
            </div>
            <div className="col-6">
              <label className="form-label">
                Format <span style={{ color: "red" }}>*</span>
              </label>
              <select className="form-control" value={format} onChange={formatOnChange} id="match-format-selection">
                <option value="" selected={true} disabled>Select format</option>
                {matchFormatList.map((item, i) => {
                  return (
                      <>
                        <option value={item.id} key={i}>
                          {item.format}
                        </option>
                      </>
                  );
                })}
              </select>
            </div>
            <div className="col-6">
              <label className="form-label">
                Series <span style={{ color: "red" }}>*</span>
              </label>
              <select className="form-control" value={series} onChange={(e) => setSeries(e.target.value)}>
                <option value="" selected={true} disabled>Select series</option>
                {seriesList.map((item, i) => {
                  return (
                      <>
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      </>
                  );
                })}
              </select>
            </div>
            <div className="col-6">
              <label className="form-label">
                Status <span style={{ color: "red" }}>*</span>
              </label>
              <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="" disabled>Select status</option>
                {statusList.map((item, i) => {
                  return (
                      <>
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      </>
                  );
                })}
              </select>
            </div>
            <div className="col-6">
              <label className="form-label">
                Team A <span style={{ color: "red" }}>*</span>
              </label>
              <br/>
              <select className="form-control" value={team_A} onChange={(e) => onChangeTeamA(e.target.value)}>
                <option value="" selected disabled>Select team A</option>
                {countryList.map((item, i) => {
                  return (
                      <>
                        <option value={item.code2l} key={i}>
                          {item.short_name} - {item.name_official}
                        </option>
                      </>
                  );
                })}
              </select>
            </div>
            <div className="col-6">
              <label className="form-label">
                Team B <span style={{ color: "red" }}>*</span>
              </label>
              <br/>
              <select className="form-control" value={team_B} onChange={(e) => onChangeTeamB(e.target.value)}>
                <option value="" selected  disabled>Select team B</option>
                {countryList.map((item, i) => {
                  return (
                      <>
                        <option value={item.code2l} key={i}>
                          {item.short_name} - {item.name_official}
                        </option>
                      </>
                  );
                })}
              </select>
            </div>
            <div className="col-6">
              <label className="form-label">
                Match Date <span style={{ color: "red" }}>*</span> <span className="text-bold-500">{matchEdit ? (moment(match_date).format("DD/MM/YYYY") ):(<></>)}</span>
              </label>
              <br/>
              <DatePicker
                  format={'DD/MM/YYYY'}
                  onChange={onChangeDate}
                  style={{width:'100%'}}
              />
              <br/>
            </div>
            <div className="col-6">
              <label className="form-label">
                Match Time (GMT)<span style={{ color: "red" }}>*</span> <span className="text-bold-500">{matchEdit ? (match_time ):(<></>)}</span>
              </label>
              <br/>
              <TimePicker style={{width:'100%'}} use24Hours format={timeFormat}  onChange={(time,timeString) => onChangeMatchTime(time,timeString)} />
              <br/>
              <Radio.Group onChange={onChangeDayNight} value={day_night}>
                <Space direction="horizontal">
                  <Radio value={"D/N"}>D/N</Radio>
                  <Radio value={"N"}>N</Radio>
                </Space>
              </Radio.Group>
            </div>
            <div className="col-12 col-md-12">
              <label className="form-label">
                Venue <span style={{ color: "red" }}>*</span>
              </label>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter venue"
                  value={venue}
                  onChange={(e) => setVenue(e.target.value)}
              />
            </div>
            <div className="col-12 col-md-12">
              <label className="form-label">
                Toss
              </label>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter toss"
                  value={toss}
                  onChange={(e) => setToss(e.target.value)}
              />
            </div>
            <div className="col-md-12 col-12">
                        <label className="form-label">
                        Score source <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                            showSearch
                            placeholder="Select a match"
                            style={{ width: '100%' }}
                            onChange={onChangeThirdPartyMatch}
                            onSearch={onSearchSeriesThirdPartyMatch}
                            filterOption={filterOptionSeriesMatch}
                            value={cricbuzz_match_id}
                        >
                            {thirdPartyMatchList.map(item => (<>
                                {item.our_match_id == null ? (<>
                                    <Option key={item.cricbuzz_match_id} value={item.cricbuzz_match_id} label={item.match_name} disabled={false}>
                                        {item.match_name} - {item.match_key_date}
                                    </Option>
                                </>) : (<>  <Option key={item.cricbuzz_match_id} value={item.cricbuzz_match_id} label={item.match_name} disabled={true}>
                                    {item.match_name} - {item.match_key_date}
                                </Option></>)}

                            </>))}
                        </Select>
                    </div>
            {matchEdit ? (<>
                <div className="col-md-6 col-12">
                  <label className="form-label">
                    Team Bat First
                  </label>
                  <br/>
                  <Radio.Group onChange={onChangeOrder} value={display_order_1}>
                    <Space direction="horizontal">
                      <Radio value={team_A}>Team A</Radio>
                      <Radio value={team_B}>Team B</Radio>
                    </Space>
                  </Radio.Group>
                </div>
                <div className="col-md-6 col-12">
                  <label className="form-label">
                    Is Active <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                      className="form-control"
                      value={is_active}
                      onChange={(e) => setIsActive(e.target.value)}
                  >
                    <option value="1"> Active</option>
                    <option value="0"> Inactive</option>
                  </select>
                </div>
            </>): (<></>) }
          </div>
      </Modal>

      <Modal
          title={resultModalTitle}
          maskClosable={false}
          visible={resultModalVisible}
          onCancel={() => setResultModalVisible(false) }
          onOk={(e) =>  {
            handleResultsSubmit(e);
          }}
          footer={[
            <Button key="back" onClick={() => setResultModalVisible(false)}> Cancel </Button>,
            <Button key="submit" type="primary" onClick={handleResultsSubmit}> Save </Button>,
          ]}
      >
        <div className="row">
            <Radio.Group onChange={onChangeWinningTeam} value={winning_team}>
              <Space direction="horizontal">
                <div className="col-12 text-center">
                <h5> &emsp; Team A &emsp;&emsp;Win</h5>
                  <label>
                    <img src={displayUrl+'/'+selected_team_A_flag} width={70} style={{marginLeft:'50px',marginRight:'40px'}}/>
                    &emsp; <Radio value={selected_team_A}></Radio></label>
                </div>
                <div className="col-12 text-center">
                  <h5>&emsp; Team B &emsp;Win</h5>
                  <img src={displayUrl+'/'+selected_team_B_flag} width={70} style={{marginLeft:'50px',marginRight:'40px'}}/>
                  <label ><Radio value={selected_team_B}></Radio></label>
                </div>
              </Space>
            </Radio.Group>

          <div className="col-6">
            <label className="form-label">
              Overs
            </label>
            <input
                type="text"
                className="form-control"
                value={team_A_overs}
                onChange={(e) => setTeamAOvers(e.target.value)}
                placeholder="Enter team A overs"
            />
          </div>
          <div className="col-6">
            <label className="form-label">
              Overs
            </label>
            <input
                type="text"
                className="form-control"
                value={team_B_overs}
                onChange={(e) => setTeamBOvers(e.target.value)}
                placeholder="Enter team B overs"
            />
          </div>
          <div className="col-6">
            <label className="form-label">
              Score
            </label>
            <input
                type="text"
                className="form-control"
                value={team_A_score}
                onChange={(e) => setTeamAScore(e.target.value)}
                placeholder="Enter team A score"
            />
          </div>
          <div className="col-6">
            <label className="form-label">
              Score
            </label>
            <input
                type="text"
                className="form-control"
                value={team_B_score}
                onChange={(e) => setTeamBScore(e.target.value)}
                placeholder="Enter team B score"
            />
          </div>
          <div className="col-12 col-md-12">
            <label className="form-label">
              Match Results
            </label>
            <input
                type="text"
                className="form-control"
                placeholder="Enter match results"
                value={match_results}
                onChange={(e) => setMatchResults(e.target.value)}
            />
          </div>
          <div className="col-6">
            <label className="form-label">
              Status
            </label>
            <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="" disabled>Select status</option>
              {statusList.map((item, i) => {
                return (
                    <>
                      <option value={item.id} key={i}>
                        {item.name}
                      </option>
                    </>
                );
              })}
            </select>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default withNamespaces()(Matches);
